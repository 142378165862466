import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';
/* import minifyTheme from 'minify-css-string' */

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {
            pt
        },
        current: 'pt',
    },
    theme: {
        dark: false,
        default: 'dark',
        themes: {
            /* options: { minifyTheme }, */
            light: {
                primary: '#cea619',
                secondary: '#070601',
                gold: '#cb972c',
                goldmono: '#D5AC54',
                verdebg: '#2A382E',
                verdebgmono: '#405546',
                verde: '#19CEA6',
                cafe: '#161203',
                azul: '#00217e',
                accent: '#45b986',
                error: '#FF5252',
                info: '#45b986',
                success: '#6AC79E',
                warning: '#FB8C00',
                monoBlack: '#1D261D',
                textos: '#44444c',
            },
            dark: {
                primary: '#d2ae6d',
                secondary: '#2F2F2F',
                'text-pink': '#072388',
                'text-white': '#FFFFFF',
                bg: '#1e1e1e',
                bg2: '#151515',
                accent: '#45b986',
                error: '#FF5252',
                info: '#45b986',
                success: '#6AC79E',
                warning: '#FB8C00',
                primaryMn: '#ffcc00',
                secondaryMn: '#6AC79E',
                terceira: '#4D3C56',
                quarta: '#bdbabe',
                fundoBranco: '#f2f2f2',
                textos: '#44444c',
            },
        },
        options: {
            /* themeCache: {
                get: key => localStorage.getItem(key),
                set: (key, value) => localStorage.setItem(key, value),
            }, */
            customProperties: true
        },
        breakpoint: {
            thresholds: {
                xs: 600,
                sm: 960,
                md: 1264,
                lg: 1904,
            },
        },
    },

});
<template>
  <v-app>
    <!-- #CABEÇALHO# -->
    <v-app-bar extended app shrink-on-scroll src="@/assets/perucas-larah.webp" class="c-app-bar"
      :class="{ 'pt-8': this.$vuetify.application.top >= 100, 'pt-0': this.$vuetify.application.top <= 110, 'c-app-bar-fixo': this.$vuetify.application.top <= 110 }">
      <template v-slot:img="{ props }">
        <v-img v-bind="props" gradient="to top right, rgba(28,28,28,0.5), rgba(0,0,0,0.8)"></v-img>
      </template>
      <!-- ICONE DE FILTROS -->
      <v-app-bar-nav-icon color="gold" dark x-large :class="{ 'c-open-filter': $vuetify.breakpoint.mobile }"
        @click="drawer = !drawer" class="mt-n3">
        <v-icon :x-large="!$vuetify.breakpoint.mobile">tune</v-icon>
      </v-app-bar-nav-icon>
      <!-- LOGO -->
      <v-toolbar-title>
        <v-img src="@/assets/logo-larah-hair.svg" :max-height="this.$vuetify.application.top <= 200 ? 50 : 80" contain
          position="center center" class="c-logo">
        </v-img>
        <transition name="slide-y-reverse">
          <v-row v-if="!(this.$vuetify.application.top <= 105)" dense class="ma-0 pa-0">
            <!-- FILTRO BUSCADOR -->
            <v-col cols="12" class="ma-0 pa-0 mt-4">
              <v-row dense align="center" align-content="center" justify="center">
                <v-col cols="12" md="9" class="ma-0 pa-0 ml-n3 ml-md-0">
                  <v-autocomplete dense height="46" dark color="primary" deletable-chips multiple chips small-chips
                    hide-details cache-items outlined label="PESQUISE O SEU CABELO" v-model="selectedTerms"
                    :items="availableTerms" @change="filterProdutos" class="c-filtro">

                  </v-autocomplete>
                  <v-chip-group dark center-active show-arrows v-model="termosR" multiple>
                    <v-chip :disabled="tagAtiv(tag)" active-class="c-chip-filter-active" class="white--text c-chip-filter"
                      filter small v-for="(tag, indeT) in termosRapidos" :key="indeT" @click="toggleTag(tag)">
                      {{ tag }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
              <!-- <v-text-field dark color="primary" hide-details dense rounded outlined v-model="searchTerm" label="PESQUISE O SEU CABELO" clearable>
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-progress-circular v-if="isLoading" size="24" color="verdebgmono" indeterminate />
                <v-icon v-else color="primary">
                  mdi-image-search-outline
                </v-icon>
              </v-fade-transition>
            </template>
          </v-text-field> -->
            </v-col>
          </v-row>
        </transition>
      </v-toolbar-title>
      <div class="c-idioma" :class="{ 'd-none': this.$vuetify.application.top <= 110 && !$vuetify.breakpoint.md }">
        <v-img @click="linkCatalogoIdioma('https://larah-br.avizvfx.com.br/')" src="@/assets/brasil.png" :max-height="32"
          contain position="center center" class="c-idioma-brasil u-cursor-ponter">
        </v-img>
        <v-img @click="linkCatalogoIdioma('https://larah-il.avizvfx.com.br/')" src="@/assets/israel.png" :max-height="32"
          contain position="center center" class="c-idioma-israel u-cursor-ponter">
        </v-img>
      </div>
    </v-app-bar>
    <!-- #FIM CABEÇALHO# -->
    <!-- #MENU LATERAL -->
    <v-navigation-drawer color="verdebgmono" v-model="drawer" fixed temporary>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="">
            <v-img position="center center" :src="require('@/assets/logo-larah-hair-white.svg')" contain aspect-ratio="1"
              class="rounded-lg" max-height="70">
            </v-img>
          </v-col>
          <!-- CATEGORIAS -->
          <v-col cols="12" class="pa-3">
            <h3 class="text-subtitle-2 gold--text">CATEGORIAS</h3>
            <v-row><v-col>
                <v-chip-group mandatory column dark center-active show-arrows v-model="categoriaSelecionada">
                  <v-chip active-class="c-chip-filter-active" class="white--text c-chip-filter-2" filter
                    @click="selecionarCategoria('todos')">
                    Todos Produtos
                  </v-chip>
                  <v-chip active-class="c-chip-filter-active" class="white--text c-chip-filter-2" filter
                    @click="selecionarCategoria('toplaces')">
                    Toplaces
                  </v-chip>
                </v-chip-group>
              </v-col></v-row>
          </v-col>
          <!-- COR DE CABELO  -->
          <v-col cols="12" class="pa-3">
            <h3 class="text-subtitle-2 gold--text">COR DO CABELO</h3>
            <v-row><v-col>
                <v-chip-group v-model="termosR1" column multiple>
                  <v-chip :disabled="tagAtiv(tag)" filter active-class="c-chip-filter-active"
                    class="white--text c-chip-filter-2" small v-for="tag in tagsCorCabelo" :key="tag"
                    @click="toggleTag(tag)">
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-col>
          <!-- MODELO -->
          <v-col cols="12" class="pa-3">
            <h3 class="text-subtitle-2 gold--text">MODELO</h3>
            <v-row><v-col>
                <v-chip-group v-model="termosR3" column multiple>
                  <v-chip :disabled="tagAtiv(tag)" filter active-class="c-chip-filter-active"
                    class="white--text c-chip-filter-2" small v-for="tag in tagsModeloCabelo" :key="tag"
                    @click="toggleTag(tag)">
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-col></v-row>
          </v-col>
          <!-- ESTILO DO CABELO -->
          <v-col cols="12" class="pa-3">
            <h3 class="text-subtitle-2 gold--text">ESTILO DO CABELO</h3>
            <v-row><v-col>
                <v-chip-group v-model="termosR2" column multiple>
                  <v-chip :disabled="tagAtiv(tag)" filter active-class="c-chip-filter-active"
                    class="white--text c-chip-filter-2" small v-for="tag in tagsEstiloCabelo" :key="tag"
                    @click="toggleTag(tag)">
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-col></v-row>
          </v-col>
          <v-col>
            <v-row dense align="center" align-content="center" justify="center">
              <v-col class="text-center">
                <v-btn icon href="https://www.facebook.com/larahcabelos" target="_blank">
                  <v-icon>
                    mdi-facebook
                  </v-icon>
                </v-btn>
                <v-btn icon href="https://www.instagram.com/larahbrazil/" target="_blank">
                  <v-icon>
                    mdi-instagram
                  </v-icon>
                </v-btn>
                <v-btn icon href="https://wa.me/4196119128" target="_blank">
                  <v-icon>
                    mdi-whatsapp
                  </v-icon>
                </v-btn>
                <v-btn icon href="https://www.larahcabelos.com.br/" target="_blank">
                  <v-icon>
                    mdi-web-box
                  </v-icon>
                </v-btn>
                <h4 class="mt-2 text-subtitle-2 u-letter-spacer-5"><v-divider />ATENDIMENTO<v-divider /></h4>
                <h4 @click="ligar()" class="text-button gold--text"><v-icon color="gold" small>headset_mic</v-icon> (41)
                  9611-9128</h4>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
    <!-- #FIM MENU LATERAL# -->
    <!-- #CORPO DA APLICAÇÃO# -->
    <v-main class="verdebg">
      <v-item-group>
        <v-container :fluid="$vuetify.breakpoint.mobile">
          <v-row dense class="mb-2 px-2 rounded">
            <v-col cols="12" class="ma-0 pa-0">
              <span class="text-caption white--text">CATEGORIAS</span>
            </v-col>
            <v-chip-group mandatory dark center-active show-arrows v-model="categoriaSelecionada">
              <v-chip label active-class="c-chip-filter-active" class="white--text c-chip-filter" filter
                @click="selecionarCategoria('todos')">
                Todos Produtos
              </v-chip>
              <v-chip label active-class="c-chip-filter-active" class="white--text c-chip-filter" filter
                @click="selecionarCategoria('toplaces')">
                Toplaces
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row :dense="$vuetify.breakpoint.mobile">
            <v-col v-for="cabelos in filteredProdutos" :key="cabelos.id" :cols="$vuetify.breakpoint.mobile ? '6' : '12'"
              md="3" :class="{ 'u-hide': ocultarCategoria(cabelos.categoria) }">

              <!-- CARD DE PERUCAS -->
              <!-- <span class="text-h2">{{ cabelos.id }}-</span> -->
              <transition name="scroll-y-reverse-transition">
                <v-item v-if="cabelos.categoria === filtroDeCategoria.perucas">
                  <v-card class="u-color-gold-especial-2" dark>
                    <!-- IMAGEM DO CARD -->
                    <v-hover v-slot="{ hover }">
                      <v-img @click="openDialogPeruca(cabelos)"
                        :lazy-src="require(`@/assets/cabelos/draft/${cabelos.id}.webp`)" :aspect-ratio="1 / 1"
                        :src="require(`@/assets/cabelos/${cabelos.id}.webp`)" class="u-cursor-ponter">

                        <!-- IMG HOVER -->
                        <v-fade-transition>
                          <v-img @click="openDialogPeruca(cabelos)" v-if="isImgPeruca(cabelos.id) && hover"
                            :lazy-src="require(`@/assets/cabelos/draft/${cabelos.id}b.webp`)" :aspect-ratio="1 / 1"
                            :src="require(`@/assets/cabelos/${cabelos.id}b.webp`)" class="u-cursor-ponter"></v-img>
                        </v-fade-transition>
                      </v-img>

                    </v-hover>
                    <v-card-text class="pt-6"
                      :class="{ 'u-color-gold-especial-2': !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs, 'u-color-gold-especial': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs, 'c-text-card': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm }"
                      style="position: relative;">
                      <v-btn @click="openDialogPeruca(cabelos)" absolute class="u-color-gold-especial white--text" fab
                        :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                        :large="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs" right top>
                        <v-icon>mdi-cart</v-icon>
                      </v-btn>
                      <!-- GRUPO DE TAGS SOBRE O CABELO -->
                      <div v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
                        class="font-weight-light black--text text-h6 c-filtros-tags ma-0 pa-0">
                        <v-chip-group active-class="gold--text" column multiple>
                          <v-chip small color="verdebg" class="text-uppercase">
                            {{ cabelos.color }}
                          </v-chip>
                          <v-chip small color="verdebg" class="text-uppercase">
                            {{ cabelos.size }}
                          </v-chip>
                          <v-chip small color="verdebg" class="text-uppercase">
                            {{ cabelos.style }}
                          </v-chip>
                        </v-chip-group>
                      </div>
                      <h3 class="text-h6 text-sm-h5 font-weight-regular azul--text mb-2 mt-0 mt-md-14"
                        :class="{ 'u-altura-font-1': !$vuetify.breakpoint.sm, 'u-altura-font-1-mobile': $vuetify.breakpoint.sm || !$vuetify.breakpoint.xs }">
                        Cabelo Humano
                      </h3>
                      <div v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                        class="font-weight-light black--text text-h6 ma-0 pa-0">
                        <v-chip-group active-class="gold--text" column multiple>
                          <v-chip x-small color="verdebg" class="text-uppercase">
                            {{ cabelos.color }}
                          </v-chip>
                          <v-chip x-small color="verdebg" class="text-uppercase">
                            {{ cabelos.size }}
                          </v-chip>
                          <v-chip x-small color="verdebg" class="text-uppercase">
                            {{ cabelos.style }}
                          </v-chip>
                        </v-chip-group>
                      </div>

                      <div v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
                        class="font-weight-light text-h6 mb-2">
                        Resistente ao Calor, Ideal <br /> Para Uso Diário.
                      </div>
                    </v-card-text>
                  </v-card>
                </v-item>
              </transition>
              <!-- CARD DE TOPLACES -->
              <transition name="scroll-y-reverse-transition">
                <v-item v-if="cabelos.categoria === filtroDeCategoria.toplaces">
                  <v-card class="u-color-gold-especial-2" dark>
                    <!-- IMAGEM DO CARD -->
                    <v-hover v-slot="{ hover }">
                      <v-img @click="openDialogPeruca(cabelos)"
                        :lazy-src="require(`@/assets/cabelos/draft/toplaces-larah-${cabelos.id}.webp`)"
                        :aspect-ratio="1 / 1" :src="require(`@/assets/cabelos/toplaces-larah-${cabelos.id}.webp`)"
                        class="u-cursor-ponter">

                        <!-- IMG HOVER -->
                        <v-fade-transition>
                          <v-img @click="openDialogPeruca(cabelos)" v-if="isImgToplaces(cabelos.id) && hover"
                            :lazy-src="require(`@/assets/cabelos/draft/toplaces-larah-${cabelos.id}b.webp`)"
                            :aspect-ratio="1 / 1" :src="require(`@/assets/cabelos/toplaces-larah-${cabelos.id}b.webp`)"
                            class="u-cursor-ponter"></v-img>
                        </v-fade-transition>
                      </v-img>

                    </v-hover>
                    <v-card-text class="pt-6"
                      :class="{ 'u-color-gold-especial-2': !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs, 'u-color-gold-especial': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs, 'c-text-card': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm }"
                      style="position: relative;">
                      <v-btn @click="openDialogPeruca(cabelos)" absolute class="u-color-gold-especial white--text" fab
                        :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                        :large="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs" right top>
                        <v-icon>mdi-cart</v-icon>
                      </v-btn>
                      <!-- GRUPO DE TAGS SOBRE O CABELO -->
                      <div v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
                        class="font-weight-light black--text text-h6 c-filtros-tags ma-0 pa-0">
                        <v-chip-group active-class="gold--text" column multiple>
                          <v-chip small color="verdebg" class="text-uppercase">
                            {{ cabelos.color }}
                          </v-chip>
                          <v-chip small color="verdebg" class="text-uppercase">
                            {{ cabelos.size }}
                          </v-chip>
                          <v-chip small color="verdebg" class="text-uppercase">
                            {{ cabelos.style }}
                          </v-chip>
                        </v-chip-group>
                      </div>
                      <h3 class="text-h6 text-sm-h5 font-weight-regular azul--text mb-2 mt-0 mt-md-14"
                        :class="{ 'u-altura-font-1': !$vuetify.breakpoint.sm, 'u-altura-font-1-mobile': $vuetify.breakpoint.sm || !$vuetify.breakpoint.xs }">
                        Toplaces
                      </h3>
                      <div v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                        class="font-weight-light black--text text-h6 ma-0 pa-0">
                        <v-chip-group active-class="gold--text" column multiple>
                          <v-chip x-small color="verdebg" class="text-uppercase">
                            {{ cabelos.color }}
                          </v-chip>
                          <v-chip x-small color="verdebg" class="text-uppercase">
                            {{ cabelos.size }}
                          </v-chip>
                          <v-chip x-small color="verdebg" class="text-uppercase">
                            {{ cabelos.style }}
                          </v-chip>
                        </v-chip-group>
                      </div>

                      <div v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
                        class="font-weight-light text-h6 mb-2">
                        Resistente ao Calor, Ideal <br /> Para Uso Diário.
                      </div>
                    </v-card-text>
                  </v-card>
                </v-item>
              </transition>
            </v-col>
          </v-row>
          <!-- DIALOGO PG CABELO -->
          <v-dialog fullscreen v-model="dialogoPeruca">
            <Peruca :whatsApp="whatsApp" :dialogoPeruca="dialogoPeruca" :dataPgPeruca="dataPgPeruca"
              @closeDialogPeruca="fecharDialogPeruca($event)"></Peruca>
          </v-dialog>
          <!-- SPEED DIAL CONTATO -->
          <v-speed-dial :open-on-hover="false" v-model="speedDialcard" fixed bottom
            :left="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
            :right="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs" transition="scale-transition" class="ml-0"
            :class="{ 'mr-n2': !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs }">
            <template v-slot:activator>
              <v-btn dark elevation="24" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs" v-model="speedDialcard"
                color="green" fab>
                <v-icon v-if="speedDialcard">
                  mdi-close
                </v-icon>
                <v-icon v-else>
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </template>
            <v-btn @click="clickWhatsApp()" fab dark :x-small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
              color="green">
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
            <v-btn @click="ligar()" fab dark :x-small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs" color="gold">
              <v-icon>headset_mic</v-icon>
            </v-btn>
          </v-speed-dial>
        </v-container>
      </v-item-group>
    </v-main>
    <!-- #FIM DO CORPO DA APLICAÇÃO -->
  </v-app>
</template>

<script>
const imgCabelos = require.context('@/assets/cabelos/', false, /\.webp$/);
import data from "@/data.json";
import toplaces from "@/toplaces.json";
import Peruca from "./components/Peruca.vue"
export default {
  components: { Peruca },
  name: 'App',
  data: (vue) => ({
    drawer: null,
    categoriaSelecionada: 0,
    termosR: [],
    termosR1: [],
    termosR2: [],
    termosR3: [],
    tagsCorCabelo: ['PRETO', 'LOIRO', 'RUIVO', 'RUIVO FECHADO', 'CASTANHO', 'CASTANHO ESCURO', 'CASTANHO CLARO', 'LOIRO ESCURO', 'PLATINADO', 'BRANCO'],
    tagsEstiloCabelo: ['CACHEADO', 'LISO', 'ONDULADO'],
    tagsModeloCabelo: ['LONGO', 'MÉDIO', 'CURTO'],
    termosRapidos: ['LONGO', 'MÉDIO', 'CURTO', 'PRETO', 'LOIRO', 'LOIRO ESCURO', 'RUIVO', 'RUIVO ESCURO', 'RUIVO FECHADO', 'CASTANHO ESCURO', 'CASTANHO CLARO', 'PLATINADO', 'ONDULADO', 'CACHEADO', 'LISO'],
    availableTerms: [],
    selectedTerms: [],
    isLoading: true,
    speedDialcard: !vue.$vuetify.breakpoint.sm && !vue.$vuetify.breakpoint.xs,
    whatsApp: '5521990785387',
    dialogoPeruca: false,
    debouncedFiltering: null,
    searchTerm: '',
    /* produtos: data.perucas, */
    produtos: data.perucas.concat(toplaces.toplaces),
    filteredProdutos: [],
    dataPgPeruca: {},
    filtroDeCategoria: {
      perucas: 'perucas',
      toplaces: 'toplaces'
    }
    //
  }),

  computed: {
    ocultarCategoria() {
      return categoria => {
        if (categoria === 'perucas') {
          if (this.categoriaSelecionada === 0) {
            return false
          } else {
            return true
          }
        }
        if (categoria === 'toplaces') {
          if (this.categoriaSelecionada === 1 || this.categoriaSelecionada === 0) {
            return false
          } else {
            return true
          }
        }
      }
    },
    tagAtiv() {
      return tag => {
        if (this.$vuetify.breakpoint.mobile && this.selectedTerms.length >= 4) {
          return true
        }
        return this.selectedTerms.filter(e => e === tag ? true : false).length !== 0 ? true : false
      }
    },
    isImgPeruca() {
      return id => {
        const imgBuscada = [`${id}b.webp`]
        for (const imagem of imgBuscada) {
          if (imgCabelos.keys().includes(`./${imagem}`)) {
            return true
          } else {
            return false
          }
        }
      }
    },
    isImgToplaces() {
      return id => {
        const imgBuscada = [`toplaces-larah-${id}b.webp`]
        for (const imagem of imgBuscada) {
          if (imgCabelos.keys().includes(`./${imagem}`)) {
            return true
          } else {
            return false
          }
        }
      }
    }
  },
  watch: {
    selectedTerms: {
      handler(newValue, oldValue) {
        const removedItem = oldValue.find(item => !newValue.includes(item));
        if (removedItem) {
          const indexRemovido1 = this.tagsCorCabelo.indexOf(removedItem);
          if (indexRemovido1 !== -1) {
            this.termosR1 = this.termosR1.filter(elemento => elemento !== indexRemovido1);
          }
          const indexRemovido2 = this.tagsEstiloCabelo.indexOf(removedItem);
          if (indexRemovido2 !== -1) {
            this.termosR2 = this.termosR2.filter(elemento => elemento !== indexRemovido2);
          }
          const indexRemovido3 = this.tagsModeloCabelo.indexOf(removedItem);
          if (indexRemovido3 !== -1) {
            this.termosR3 = this.termosR3.filter(elemento => elemento !== indexRemovido3);
          }

          const indexRemovido = this.termosRapidos.indexOf(removedItem);
          if (indexRemovido !== -1) {
            this.termosR = this.termosR.filter(elemento => elemento !== indexRemovido);
          }
        }

        this.filterProdutos(newValue);
      },
      deep: true
    }

    /* searchTerm: {
      handler(newTerm) {
        this.isLoading = true
        // Define um tempo de atraso em milissegundos
        const debounceTime = 300; // 300ms, ajuste conforme necessário

        // Cancela o debounce anterior, se houver
        if (this.debouncedFiltering) {
          clearTimeout(this.debouncedFiltering);
        }

        // Configura um novo debounce
        this.debouncedFiltering = setTimeout(() => {
          this.filterProdutos(newTerm);
        }, debounceTime);
      },
      immediate: true
    }, */
  },
  methods: {
    selecionarCategoria(pagina) {
      /* this.categoriaSelecionada[this.categoriaSelecionada]
      this.categoriaSelecionada.splice()
      this.categoriaSelecionada.filter((ele, ind) => {
        ind
      } ) */
      /*  filtroDeCategoria: {
       perucas: 'perucas',
       toplaces: 'toplaces'
     } */

      if (pagina === 'perucas') {
        this.filtroDeCategoria.toplaces = 'hide'
        this.filtroDeCategoria.perucas = 'perucas'
      }
      if (pagina === 'toplaces') {
        this.filtroDeCategoria.perucas = 'hide'
        this.filtroDeCategoria.toplaces = 'toplaces'
      }
      if (pagina === 'todos') {
        this.filtroDeCategoria.perucas = 'perucas'
        this.filtroDeCategoria.toplaces = 'toplaces'
      }
    },
    linkCatalogoIdioma(link) {
      window.open(link, '_blank');
    },
    toggleTag(tag) {
      const index = this.selectedTerms.indexOf(tag);
      if (index !== -1) {
        this.selectedTerms.splice(index, 1);
      } else {
        this.selectedTerms.push(tag);
      }
    },
    initializeTerms() {
      const terms = new Set();

      this.produtos.forEach((peruca) => {
        terms.add(peruca.color);
        terms.add(peruca.size);
        terms.add(peruca.style);
      });

      this.availableTerms = Array.from(terms);
    },

    filterProdutos(terms) {
      if (!terms || terms.length === 0) {
        this.termosR = []
        this.filteredProdutos = this.produtos;
        return;
      }
      this.filteredProdutos = this.produtos.filter((peruca) => {
        return terms.every((term) => {
          return (
            peruca.color.toLowerCase().includes(term.toLowerCase()) ||
            peruca.size.toLowerCase().includes(term.toLowerCase()) ||
            peruca.style.toLowerCase().includes(term.toLowerCase())
          );
        });
      });
    },

    clickWhatsApp() {
      const message = `Olá! Gostaria de informações sobre o catálogo de cabelos LARAH HAIR`;
      const encodedMessage = encodeURIComponent(message);
      const url = `https://wa.me/${this.whatsApp}?text=${encodedMessage}`;
      window.open(url, '_blank');
    },
    ligar() {
      const phoneNumber = '554196119128';
      const url = `tel:+${phoneNumber}`;
      window.open(url, '_blank');
    },
    fecharDialogPeruca() {
      this.dialogoPeruca = false
    },
    openDialogPeruca(peruca) {
      const currentUrl = new URL(window.location.href);
      const existingPerucaId = currentUrl.searchParams.get('perucaId');

      if (!existingPerucaId || existingPerucaId !== peruca.id.toString()) {
        currentUrl.searchParams.set('perucaId', peruca.id);
        window.history.pushState({}, '', currentUrl);
      }

      this.dataPgPeruca = Object.assign({}, peruca)

      // Abre o v-dialog
      this.dialogoPeruca = true;
    }
    /* openDialogPeruca(peruca) {
      // Define a URL com os parâmetros da peruca selecionada
      const url = window.location.href + '?perucaId=' + peruca.id;

      // Atualiza a URL do navegador com os parâmetros da peruca
      window.history.pushState({}, '', url);

      this.dataPgPeruca = Object.assign({}, peruca)

      // Abre o v-dialog
      this.dialogoPeruca = true;
    } */
  },
  created() { },
  beforeMount() { },
  mounted() {
    this.initializeTerms();
    this.filterProdutos();

    const urlParams = new URLSearchParams(window.location.search);
    const perucaId = urlParams.get('perucaId');
    // Se houver um perucaId na URL, abre o v-dialog correspondente
    if (perucaId) {
      const dataPeruca = this.produtos.filter(e => e.id == perucaId)[0]
      this.dataPgPeruca = {}
      this.dataPgPeruca = Object.assign({}, dataPeruca)
      this.dialogoPeruca = true;
    }
  }
};
</script>
<style>
.u-hide {
  display: none !important;
}

.c-idioma {
  position: absolute;
  display: flex;
  width: 29px;
  right: 42px;
  top: -20px;
  opacity: 0.8;
}

.u-letter-spacer-5 {
  letter-spacing: 5px !important;
}

.c-logo {
  transition: max-height 0.3s ease;
}

.slide-y-reverse-enter-active,
.slide-y-reverse-leave-active {
  transition: transform 0.3s ease;
}

.slide-y-reverse-enter,
.slide-y-reverse-leave-to {
  transform: translateY(100%);
}

.c-open-filter {
  position: absolute;
  z-index: 100 !important;
}

.c-app-bar-fixo {
  height: 70px !important;
}

/* .c-app-bar-fixo .v-image {
    position: absolute;
    top: -25px;
} */
.c-app-bar-fixo .v-toolbar__content {
  top: -17px;
}

.c-app-bar-fixo .v-toolbar__extension {
  display: none;
}

.c-app-bar-fixo .v-responsive__content {
  transition: 500ms cubic-bezier(0, 1.13, 0, 1.02);
}

@media only screen and (max-width: 1024px) {
  .c-app-bar-fixo .v-image {
    position: absolute;
    left: 0px;
    right: 0px;
    top: -13px;
  }
}

.u-cursor-ponter {
  cursor: pointer;
}

.c-app-bar {
  border-bottom: solid 1px rgb(77 77 77 / 59%) !important;
}

.c-chip-filter-categoria.v-chip--active {
  background: #cb972c !important;
}

.c-chip-filter {
  background: #405546 !important;
}

.c-chip-filter-2 {
  background: #2A382E !important;
}

.c-chip-filter-active {
  background: #cb972c !important;
}

.v-select__slot div:nth-child(3) .v-input__icon>button {
  margin-right: 51px;
}

.v-select__slot div:nth-child(4) i {
  font-size: 60px !important;
  margin-top: 6px;
}

.v-select__slot div:nth-child(4) {
  border-radius: 8px;
}

.c-filtro .v-chip {
  background: #cb972c !important;
}

.v-autocomplete__content .v-select-list {
  background: #2a382e !important;
}

.v-autocomplete__content .v-list-item,
.v-list-item i {
  color: white !important;
}

.u-altura-font-1-mobile {
  line-height: 1.5rem !important;
  font-weight: 500 !important;
}

.u-altura-font-1 {
  line-height: 1.7rem !important;
}

.c-filtros-tags {
  position: absolute;
  top: 2px;
  left: 12px;
  right: 0;
  width: 189px;
}

.c-text-card {
  min-height: 172px !important;
}

@media only screen and (max-width: 951px) and (min-width: 425px) {
  .c-text-card {
    min-height: 100px !important;
  }
}

.u-color-gold-especial {
  background: #cb972c;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #d3a133 30%, #be902e 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #d69e2d 62.5%, #f4b330 100%) !important;
}

.u-color-gold-especial-2 {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #6b4800 62.5%, #694e11 100%);
}

/* 
.u-color-gold-especial{
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)!important;
}
.u-color-gold-especial{
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #6b4800 62.5%, #694e11 100%);
} */

/*ScrolBar*/
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #cb972c;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #405546;
}
::-webkit-scrollbar-track {
  background: #405546;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #405546;
}
::-webkit-scrollbar-track:active {
  background: #405546;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>

<template>
    <v-card color="verdebg" class="ma-0 pa-0">
        <v-btn small color="verdebgmono" absolute right fab dark @click="closeDialogPeruca()" class="mt-8 mr-n1 mr-sm-0">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text class="ma-0 pa-3">
            <v-row>
                <v-col cols="12" class="mb-n15 mb-sm-auto">
                    <v-img position="center center" :src="require('@/assets/logo-larah-hair-white.svg')" contain
                        aspect-ratio="1" class="rounded-lg" max-height="70">
                    </v-img>
                </v-col>
                <!-- CARROSSEL DE IMGS DOS CABELOS E PLAYER DE VIDEO  -->
                <!-- <v-col cols="12" md="6">
                    <v-carousel class="c-carrossel" :height="$vuetify.breakpoint.xl ? '800' : '480'" hide-delimiters
                        continuous cycle interval="3000" show-arrows-on-hover>
                        <v-carousel-item v-for="n in imagensPeruca" :key="`card-${n}`">
                            <v-row class="fill-height" align="center" justify="center">
                                <v-col cols="12" sm="6" md="9" xl="11" class="pa-8 pa-xl-1">
                                    <v-img position="center center" :lazy-src="require(`@/assets/cabelos/draft/${n}`)"
                                        :src="require(`@/assets/cabelos/${n}`)" contain aspect-ratio="1" class="rounded-lg"
                                        :max-height="$vuetify.breakpoint.xl ? '800' : '580'">
                                    </v-img>
                                </v-col>
                            </v-row>
                        </v-carousel-item>
                    </v-carousel>
                </v-col> -->

                <!-- CARROSSEL DE IMGS DOS CABELOS -->
                <v-col cols="12" md="6" class="">
                    <v-carousel class="c-carrossel" :height="$vuetify.breakpoint.xl ? '800' : '480'" hide-delimiters
                        continuous cycle interval="3000" show-arrows-on-hover>
                        <v-carousel-item v-for="n in imagensPeruca" :key="`card-${n}`">
                            <v-row class="fill-height" align="center" justify="center">
                                <v-col cols="12" sm="6" md="9" xl="11" class="pa-8 pa-xl-1">
                                    <v-img position="center center" :lazy-src="require(`@/assets/cabelos/draft/${n}`)"
                                        :src="require(`@/assets/cabelos/${n}`)" contain aspect-ratio="1" class="rounded-lg"
                                        :max-height="$vuetify.breakpoint.xl ? '800' : '580'"></v-img>
                                </v-col>
                            </v-row>
                        </v-carousel-item>
                    </v-carousel>
                    <v-img v-if="this.dataPgPeruca.categoria === 'toplaces'" :src="require('@/assets/videos/thumbnail-larah-cabelos.png')" height="80" contain
                        @click="showVideoPlayer = true" class="u-cursor-ponter c-thumbnail-video d-none d-md-block">
                    </v-img>
                    <!-- PLAYER DE VÍDEO -->
                    <v-dialog overlay-color="verdebg" overlay-opacity="0.9" v-model="showVideoPlayer" max-width="680"
                        content-class="elevation-0 mt-n7">
                        <v-card :elevation="0" class="ma-0 pa-0" color="transparent" flat>
                            <v-card-actions>
                                <v-spacer />
                                <!-- Pausar video -->
                                <v-btn top right fab icon small color="gold" @click="pauseVideo" class="transparent">
                                    <v-icon v-if="playPause">pause_circle_outline</v-icon>
                                    <v-icon v-else>play_arrow</v-icon>
                                </v-btn>
                                <!-- Maximiar video -->
                                <v-btn top right fab icon small color="gold" @click="toggleMaximizeVideo"
                                    class="transparent">
                                    <v-icon>aspect_ratio</v-icon>
                                    <!--  {{ isVideoMaximized ? 'Restaurar' : 'Maximizar' }} -->
                                </v-btn>
                                <!-- Fechar dialog -->
                                <v-btn top right fab icon small color="gold" @click="showVideoPlayer = false"
                                    class="transparent mr-n3"><v-icon>highlight_off</v-icon></v-btn>
                            </v-card-actions>
                            <v-card-text class="ma-0 pa-0 text-center">
                                <div class="video-container">
                                    <video ref="videoPlayer" controls @loadedmetadata="playVideoOnLoad">
                                        <source :src="require('@/assets/videos/Toplaces-Larah-Cabelos-x2.mp4')"
                                            type="video/mp4">
                                        Seu navegador não suporta o elemento de vídeo.
                                    </video>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-col>

                <v-col cols="12" md="4" class="pa-8 ml-xl-n16"
                    :class="{ 'c-info-produto': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs }">
                    <v-img v-if="this.dataPgPeruca.categoria === 'toplaces'" :src="require('@/assets/videos/thumbnail-larah-cabelos.png')" height="80" contain
                        @click="showVideoPlayer = true" class="u-cursor-ponter ma-0 pa-0 c-thumbnail-video d-md-none">
                    </v-img>
                    <h1 class="font-weight-regular gold--text py-3 py-sm-auto u-mt-xs"
                        :class="{ 'text-h2': !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs, 'text-h3': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs }">
                        {{ dataPgPeruca.name }}</h1>
                    <v-chip-group dark active-class="white--text" multiple class="mt-1">
                        <v-chip color="gold" class="text-uppercase">
                            {{ dataPgPeruca.color }}
                        </v-chip>
                        <v-chip color="gold" class="text-uppercase">
                            {{ dataPgPeruca.size }}
                        </v-chip>
                        <v-chip color="gold" class="text-uppercase">
                            {{ dataPgPeruca.style }}
                        </v-chip>
                    </v-chip-group>
                    <v-divider dark class="my-3" />
                    <h3 class="text-h5 white--text mb-3">{{ dataPgPeruca.description }}</h3>
                    <v-btn dark color="verdebgmono" @click="scrollToTop('link-qualidade')" block class="mt-3">
                        <v-icon class="mr-1" dark>
                            auto_awesome
                        </v-icon>
                        CONHEÇA A QUALIDADE
                    </v-btn>
                    <v-btn @click="clickWhatsApp()" dark color="gold" block class="mt-3">
                        <v-icon class="mr-1" dark>
                            mdi-whatsapp
                        </v-icon>
                        FAÇA SEU PEDIDO
                    </v-btn>
                </v-col>
                <!-- TABS SOBRE O PRODUTO -->
                <v-col cols="12">
                    <v-card v-intersect="onIntersect">
                        <v-tabs v-model="tab" class="u-color-gold-especial-tab" centered dark icons-and-text>
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-1">
                                QUALIDADE
                                <v-icon>auto_awesome</v-icon>
                            </v-tab>

                            <v-tab href="#tab-2">
                                CONSERVAÇÃO
                                <v-icon>warning</v-icon>
                            </v-tab>

                            <v-tab href="#tab-3">
                                GARANTIA
                                <v-icon>mdi-medal</v-icon>
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item :key="1" :value="'tab-1'">
                                <v-card dark flat rounded="0" color="verdebgmono">
                                    <v-card-text>
                                        <v-row id="link-qualidade">
                                            <v-col cols="12">
                                                <h3 class="text-h5 text-sm-h4 pa-3 gold--text font-weight-light">Cabelos de
                                                    Qualidade<br />Premium:
                                                    <span class="font-weight-bold">Transforme-se</span> com
                                                    <span class="font-weight-bold">
                                                        Larah Cabelos
                                                    </span>
                                                </h3>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <p class="c-text-qualidade pa-1 pa-sm-4">Deseja um visual simplesmente
                                                    deslumbrante?
                                                    <span class="font-weight-black">Larah Cabelos</span> é
                                                    a escolha perfeita para uma transformação impactante e de qualidade
                                                    impecável.<br /> <br />

                                                    Com mais de 15 anos de experiência em cortes de cabelos, a renomada
                                                    Larah
                                                    Cabelos é a escolha certa para transformar seu visual e realçar sua
                                                    beleza
                                                    única. Nossa equipe de técnicos profissionais e experientes está pronta
                                                    para
                                                    oferecer um atendimento excepcional e garantir que você tenha a melhor
                                                    experiência possível.<br /> <br />

                                                    Na <span class="font-weight-black">Larah Cabelos</span>, acreditamos que
                                                    a qualidade é fundamental. Nossos
                                                    cabelos passam por um exclusivo processo
                                                    de
                                                    clareamento que preserva a saúde e a naturalidade dos fios. Isso
                                                    significa que
                                                    você pode desfrutar de total liberdade para realizar qualquer
                                                    procedimento
                                                    químico que desejar, sem restrições!<br /> <br />

                                                    Se você busca resultados surpreendentes, confie em uma empresa de
                                                    credibilidade
                                                    reconhecida no mercado nacional e internacional. Com a <span
                                                        class="font-weight-black">Larah Cabelos</span>,
                                                    você terá
                                                    acesso a cabelos de qualidade excepcional, capazes de transformar
                                                    completamente
                                                    sua aparência e elevar sua autoconfiança.<br /> <br />

                                                    Não perca a oportunidade de descobrir a magia dos nossos cabelos
                                                    premium.
                                                    Deixe-nos ajudá-lo a conquistar o look dos seus sonhos e prepare-se para
                                                    se
                                                    surpreender com os resultados incríveis que apenas a <span
                                                        class="font-weight-black">Larah Cabelos</span> pode
                                                    oferecer!<br /> <br />

                                                    <span class="font-weight-bold"> ACESSE NOSSO SITE PARA SABER MAIS:
                                                        <v-btn color="gold" block text tile
                                                            href="https://www.larahcabelos.com.br" target="_blank">
                                                            larahcabelos.com.br
                                                        </v-btn>
                                                    </span>
                                                </p>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <v-row dense>
                                                    <v-col @click="zooImg()" v-for="(quali, index) in qualidades"
                                                        :key="index" class="d-flex child-flex mt-sm-auto u-cursor-ponter"
                                                        :class="{ 'mt-n8': index <= 2 }" cols="4">
                                                        <v-img :src="quali" contain
                                                            class="grey lighten-2 c-img-qualidade rounded-lg">
                                                            <template v-slot:placeholder>
                                                                <v-row class="fill-height ma-0" align="center"
                                                                    justify="center">
                                                                    <v-progress-circular indeterminate
                                                                        color="grey lighten-5"></v-progress-circular>
                                                                </v-row>
                                                            </template>
                                                        </v-img>
                                                    </v-col>
                                                    <v-col class="d-flex child-flex" cols="12">
                                                        <v-img :src="require(`@/assets/transformacao/transformacao.png`)"
                                                            contain class="grey lighten-2 c-img-qualidade rounded-lg">
                                                            <template v-slot:placeholder>
                                                                <v-row class="fill-height ma-0" align="center"
                                                                    justify="center">
                                                                    <v-progress-circular indeterminate
                                                                        color="grey lighten-5"></v-progress-circular>
                                                                </v-row>
                                                            </template>
                                                        </v-img>
                                                    </v-col>
                                                    <v-col cols="12"><v-btn color="gold" block text tile
                                                            href="https://www.larahcabelos.com.br" target="_blank">
                                                            larahcabelos.com.br
                                                        </v-btn></v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item :key="2" :value="'tab-2'">
                                <v-card dark flat rounded="0" color="verdebgmono">
                                    <v-card-text>
                                        <h3 class="text-h5 text-sm-h4 pa-3 gold--text font-weight-light">Conservação:<br />
                                            <span class="font-weight-bold">Transforme-se</span> com
                                            <span class="font-weight-bold">
                                                Larah Cabelos
                                            </span>
                                        </h3>
                                        <div class="pa-5">
                                            <h4 class="gold--text text-h5 pb-1">1. Armazenamento adequado:</h4>
                                            <p>Para garantir a longevidade de seus cabelos, é essencial
                                                armazená-la corretamente quando não estiver em uso. Recomendamos utilizar um
                                                suporte para perucas ou um suporte de cabeça especialmente projetado para
                                                mantê-la na forma correta. Evite deixar os cabelos jogados ou dobrados em
                                                uma gaveta, pois isso pode comprometer sua estrutura e causar emaranhamento
                                                dos
                                                fios.</p>

                                            <h4 class="gold--text text-h5 pb-1">2. Hidratação regular:</h4>
                                            <p>Assim como nossos cabelos naturais, nossos cabelos também
                                                precisam de hidratação regular. Use um condicionador sem enxágue para manter
                                                os fios macios e sedosos. Além disso, aplique um óleo de cabelo de qualidade
                                                nas pontas para evitar o ressecamento e o aparecimento de pontas duplas.
                                                Hidratar seus cabelos regularmente ajudará a preservar seu brilho e
                                                vitalidade.</p>

                                            <h4 class="gold--text text-h5 pb-1">3. Proteção solar:</h4>
                                            <p>Assim como nossa pele, os cabelos também precisam de proteção solar. Se você
                                                planeja usar seus cabelos sob o sol direto, é recomendável aplicar um
                                                produto
                                                com filtro UV para evitar danos causados pelos raios solares. Isso ajudará a
                                                manter a cor e a textura dos fios por mais tempo, preservando seus cabelos
                                                com
                                                uma aparência vibrante.</p>

                                            <v-spacer></v-spacer>
                                            <p><em><strong>Lembre-se</strong> de tratá-los com carinho e cuidado, como faria
                                                    com seus próprios
                                                    cabelos, e ela continuará a ser uma peça de destaque em seu estilo
                                                    pessoal. Agora, você pode aproveitar a versatilidade e a beleza dos
                                                    produtos da <strong>Larah Cabelos</strong> no dia a dia e em ocasiões
                                                    especiais que estão por vir.</em></p>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item :key="3" :value="'tab-3'">
                                <v-card dark flat rounded="0" color="verdebgmono">
                                    <v-card-text>
                                        <h3 class="text-h5 pb-1 text-sm-h4 pa-3 gold--text font-weight-light">Garantia de
                                            Qualidade e
                                            Satisfação:<br />
                                            <span class="font-weight-bold">Transforme-se</span> com
                                            <span class="font-weight-bold">
                                                Larah Cabelos
                                            </span>
                                        </h3>
                                        <div class="pa-5">
                                            <h4 class="gold--text text-h5 pb-1 pb-1">Compromisso:</h4>
                                            <p>Na <strong>Larah Cabelos</strong>, temos o compromisso de oferecer cabelos de
                                                alta qualidade que atendam às suas expectativas. Valorizamos cada cliente e
                                                queremos garantir que você esteja completamente satisfeito com sua compra. É
                                                por isso que oferecemos uma garantia que visa assegurar sua tranquilidade e
                                                confiança ao adquirir nossos produtos. Leia abaixo os detalhes da nossa
                                                garantia exclusiva.</p>

                                            <h4 class="gold--text text-h5 pb-1">Garantia de Qualidade e Satisfação:</h4>
                                            <p>Estamos confiantes na qualidade excepcional de nossos cabelos. Caso você
                                                encontre algum defeito de fabricação no momento da
                                                entrega, ficaremos felizes em trocar o produto sem nenhum custo adicional.
                                                Sua satisfação é a nossa prioridade!

                                                Além disso, entendemos que escolher o cabelo perfeito pode ser desafiador.
                                                Portanto, oferecemos uma política de troca flexível. Se, após receber seu
                                                cabelo, você não estiver completamente satisfeito com a cor ou o estilo
                                                escolhido, entre em contato conosco dentro de 2 dias a partir da data de
                                                recebimento para solicitar uma troca. Estaremos prontos para ajudá-lo a
                                                encontrar seu cabelo ideal que atenda às suas preferências.</p>

                                            <h4 class="gold--text text-h5 pb-1">Compromisso com a Durabilidade:</h4>
                                            <p>Nossos cabelos são confeccionadas com materiais de alta qualidade e passam
                                                por rigorosos processos de produção para garantir sua durabilidade. No
                                                entanto, entendemos que o desgaste natural pode ocorrer ao longo do tempo.
                                                Portanto, oferecemos um serviço de manutenção profissional para ajudar a
                                                prolongar a vida útil de seus cabelos. Entre em contato conosco para obter
                                                mais informações sobre nossos serviços de manutenção.</p>

                                            <h4 class="gold--text text-h5 pb-1">Atendimento ao Cliente Excepcional:</h4>
                                            <p>Na <strong>Larah Cabelos</strong>, estamos empenhados em oferecer um
                                                atendimento ao cliente excepcional. Nossa equipe está pronta para responder
                                                a todas as suas dúvidas, fornecer orientações e resolver qualquer problema
                                                que possa surgir. Estamos aqui para garantir que sua experiência de compra
                                                seja tranquila e satisfatória em todos os aspectos.</p>

                                            <v-spacer></v-spacer>
                                            <p><em>Com a <strong>Garantia de Qualidade e Satisfação</strong> da <strong>
                                                        Larah Cabelos</strong>, você pode comprar seus cabelos com
                                                    confiança,
                                                    sabendo que estamos comprometidos em superar suas expectativas. Nossa
                                                    prioridade é sua satisfação e faremos tudo ao nosso alcance para
                                                    garantir que você esteja completamente satisfeito com sua escolha. Conte
                                                    conosco para proporcionar a você uma experiência excepcional e cabelos
                                                    que realce sua beleza e estilo pessoal.</em></p>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <!-- SPEED DIAL PARA CONTATO -->
        <v-speed-dial :open-on-hover="false" v-model="speedDial" fixed bottom right transition="scale-transition"
            class="mr-n1 mr-sm-1">
            <template v-slot:activator>
                <v-btn :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs" v-model="speedDial" color="gold" dark fab>
                    <v-icon v-if="speedDial">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-whatsapp
                    </v-icon>
                </v-btn>
            </template>
            <v-btn @click="clickWhatsApp()" fab dark :x-small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                color="green">
                <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
            <v-btn @click="ligarPgInterna()" fab dark :x-small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                color="gold">
                <v-icon>headset_mic</v-icon>
            </v-btn>
            <v-btn @click="closeDialogPeruca()" fab dark :x-small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                color="verdebgmono">
                <v-icon class="u-rotacao-90">mdi-arrow-down-left-bold</v-icon>
            </v-btn>
        </v-speed-dial>

        <!-- CARROSSEL DE IMAGENS DENTRO DO DIALOG SOBRE QUALIDADE DOS CABELOS -->
        <v-dialog v-model="zooImgDialog" content-class="elevation-0 ma-0 pa-0" overlay-opacity="0.9" overlay-color="black">
            <v-row dense class="mt-0 pt-0 pb-0 u-p-absoluto u-top-50">
                <v-col cols="12" class="text-center mt-0 pt-0 u-p-relative">
                    <v-spacer />
                    <v-btn dark @click="zooImgDialog = false" absolute top fab color="monoBlack"
                        class="mt-1 text-center c-btn-fechar-zoon">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer />
                </v-col>
            </v-row>
            <v-card flat color="transparent" class="ma-0 pa-0">
                <v-card-text class="ma-0 pa-0">
                    <v-carousel height="100%" class="c-carrossel-2 ma-0 pa-0" hide-delimiter-background cycle continuous
                        interval="4000">
                        <v-carousel-item v-for="(qualiZoom, zomIndex) in qualidades" :key="zomIndex">
                            <v-row dense class="fill-height ma-0 pa-0" align="center" justify="center">
                                <v-col cols="12" md="6" xl="10" class="ma-0 pa-0">
                                    <v-img :src="qualiZoom" class="rounded-lg" contain max-height="850">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate
                                                    color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-col>
                            </v-row>
                        </v-carousel-item>
                    </v-carousel>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-card>
</template>
  
<script>
const contexto = require.context('@/assets/cabelos/', false, /\.webp$/);
const qualidade = require.context('@/assets/qualidade', false, /\.(png|jpe?g)$/).keys().map((path) => {
    return require('@/assets/qualidade' + path.slice(1));
});

export default {
    name: "PaginaDaPeruca",
    props: {
        dialogoPeruca: Boolean,
        dataPgPeruca: Object,
        whatsApp: String
    },
    components: {},
    data: () => ({
        playPause: true,
        showVideoPlayer: false,
        isVideoMaximized: false,
        zooImgDialog: false,
        speedDial: false,
        qualidades: [],
        imagensPeruca: [],
        tab: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }),
    computed: {},
    watch: {
        dialogoPeruca: {
            handler(novo) {
                if (novo) {
                    this.getImgPerucas()
                } else {
                    this.imagensPeruca = []
                }
            },
            immediate: true
        },
    },
    methods: {
        pauseVideo() {
            const video = this.$refs.videoPlayer;
            if (video.paused) {
                this.playPause = true
                video.play();
            } else {
                this.playPause = false
                video.pause();
            }
        },
        toggleMaximizeVideo() {
            const video = this.$refs.videoPlayer;
            if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            }
        },
        playVideoOnLoad() {
            const video = this.$refs.videoPlayer;
            video.play();
        },
        ligarPgInterna() {
            const phoneNumber = '554196119128';
            const url = `tel:+${phoneNumber}`;
            window.open(url, '_blank');
        },
        clickWhatsApp() {
            const message = `Não apague essa mensagem: *Orçamento para o cabelo* *Id: Cabelo#**${this.dataPgPeruca.id}* *Ref:* *${this.dataPgPeruca.color}*, *${this.dataPgPeruca.size}* e *${this.dataPgPeruca.style}*`;
            const encodedMessage = encodeURIComponent(message);
            const url = `https://wa.me/${this.whatsApp}?text=${encodedMessage}`;
            window.open(url, '_blank');
        },
        zooImg() {
            this.zooImgDialog = true
        },
        onIntersect(entries) {
            for (let val of entries) {
                this.speedDial = val.isIntersecting
            }
        },
        scrollToTop(getTarget) {
            document.getElementById(getTarget).scrollIntoView({ behavior: "smooth" });
        },
        getImgPerucas() {
            const imagens = [`${this.dataPgPeruca.id}.webp`, `${this.dataPgPeruca.id}b.webp`, `toplaces-larah-${this.dataPgPeruca.id}.webp`, `toplaces-larah-${this.dataPgPeruca.id}b.webp`]
            for (const imagem of imagens) {
                if (contexto.keys().includes(`./${imagem}`)) {
                    this.imagensPeruca.push(imagem)
                }
            }
        },
        closeDialogPeruca() {
            // Fecha o v-dialog
            this.$emit("closeDialogPeruca", false);

            // Limpa os parâmetros da peruca da URL
            window.history.replaceState({}, '', window.location.pathname);
        }
    },
    created() {
        this.qualidades = qualidade
    },
    mounted() { },
    beforeDestroy() { },
};
</script>
  
<style>
.c-dialog-video {
    box-shadow: 0px 0px red !important;
}

.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    /* Relação de aspecto de 16:9 (9 / 16 * 100) */
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.u-top-50 {
    top: 50px;
}

.u-p-absoluto {
    position: absolute !important;
}

.u-p-relative {
    position: relative;
}

.u-rotacao-90 {
    transform: rotate(90deg);
}

.c-info-produto {
    padding: 0px !important;
    margin-top: -110px;
}

@media only screen and (min-width: 1378px){

    .c-thumbnail-video {
        top: 15px !important;
    }
}
.c-img-qualidade {
    border: solid 2px #cb972c !important;
}

.u-color-gold-especial-tab .v-item-group {
    background: #cb972c;
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
        radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #6b4800 62.5%, #694e11 100%);
}

.c-text-qualidade {
    text-align: justify;
    width: 380px;
    margin: 0 auto;
}

@media only screen and (max-width: 320px) {
    .u-mt-xs {
        margin-top: -40px !important;
    }
    .c-thumbnail-video {
        top: -38px;
    }
}

@media only screen and (min-width: 1920px) {
    .c-text-qualidade {
        text-align: justify;
        width: auto;
        font-size: 23px;
        line-height: 2.2rem;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 423px) {
    .c-text-qualidade {
        text-align: left;
        width: 265px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1198px) and (min-width: 960px) {
    .c-text-qualidade {
        text-align: justify;
        width: 305px;
        margin: 0 auto;
    }
    .c-thumbnail-video {
        top: -41px !important;
    }
}

@media only screen and (max-width: 942px) and (min-width: 608px) {
    .c-text-qualidade {
        text-align: justify;
        width: initial;
        margin: 0px 20px !Important;
    }
}
</style>